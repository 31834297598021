import * as React from "react";
import DefaultLayout from "../layouts/default";
import ApplicationContainer from "../containers/apply";
export default () => {
    return (
        <>
            <DefaultLayout title="Instant Credit Loans">
                <ApplicationContainer
                    headline1={<>
                        <h5 className="text-gray-500 pt-4 text-lg font-bold text-md pb-2">
                            Instant Loans | Bad Credit Accepted<br />
                            Get $1,000 - $50,000 Same Day
                        </h5>
                    </>}
                />
            </DefaultLayout>

        </>
    );
};